<template>
  <div class="bc">
    <div class="release w">
      <div class="release_con">
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con copy_con">
                <span></span>
                <h3>产权发布</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <el-row class="title">
          <span class="one">1</span>
          <span>基本信息</span>
        </el-row>
        <el-form
          label-width="90px"
          :model="release"
          :label-position="labelPosition"
        >
          <!-- <el-form-item label="产权图片">
            <el-upload
              :on-change="fnI"
              action=""
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewG"
              :on-remove="handleRemoveG"
              :auto-upload="false"
              accept="image/*"
              :class="{ hide: hideUploadG }"
              class="avatar-uploader"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item> -->
          <el-form-item label="产权类别">
            <el-radio-group v-model="release.types">
              <el-radio
                :label="item.id"
                v-for="item in patents"
                :key="item.index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产权名称">
            <el-input
              v-model="release.name"
              placeholder="请填写产权名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请人">
            <el-input
              v-model="release.apply_name"
              placeholder="请填写申请人"
            ></el-input>
          </el-form-item>
            <el-form-item label="联系电话">
            <el-input
              v-model="release.phone"
              placeholder="请填写联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="release.registration_date"
              type="date"
              placeholder="请填写申请日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker
              v-model="release.begin_date"
              type="date"
              placeholder="请填写开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到期时间">
            <el-date-picker
              v-model="release.end_date"
              type="date"
              placeholder="请填写到期时间"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="产权类型">
            <el-radio-group v-model="release.type">
              <el-radio
                :label="item.id"
                v-for="item in type"
                :key="item.index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="发布性质">
            <el-radio-group v-model="release.apply_class">
              <!-- <el-radio label="1">个人</el-radio>
              <el-radio label="2">企业</el-radio> -->
               <el-radio
                :label="item.id"
                v-for="item in Apple"
                :key="item.index"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="出售金额" >
            <el-input style="width:95%;margin-right:10px"
              v-model="release.sale_money"
              placeholder="请填写出售金额" type="number"
            ></el-input><span>元</span>
          </el-form-item>
          <!-- <el-form-item label="知识产权图片">
            <el-upload
              :on-change="fn"
              class="upload-demo"
              ref="upload"
              action=""
              :file-list="fileList"
              :auto-upload="false"
              :limit="3"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              list-type="picture"
              accept="image/*"
            >
              <el-button plain size="mini">上传图片</el-button>
              <div slot="tip" class="el-upload__tip">
                支持扩展名：.png .jpg...
              </div>
            </el-upload>
          </el-form-item> -->
          <el-form-item label="产权编号">
            <el-input
              v-model="release.notary_number"
              placeholder="请填写产权编号"
            ></el-input>
          </el-form-item>
                    <el-form-item label="产权详情">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="release.ptext"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="subtmit-btn-box">
          <el-button type="primary" @click="onRelease">发布</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PatentAdd, PType,Patents,ApplyClass } from "../../../common/js/api";
import { uploadImg } from "../../../common/img/apiimg";
import { handleCompressImg } from "../../../api";
export default {
  name: "ReleaseNei",
  data() {
    return {
      labelPosition: "left",
      release: {
        member_id: '',
        // picture: "",
        name: "",
        apply_name: "",
        registration_date: "",
        begin_date: "",
        end_date: "",
        type: "",
        apply_class: "",
        sale_money: "",
        // notary_picture: [],
        notary_number: "",
        ptext:'',
        types:'',
        phone:''
      },
      dialogVisible: false,
      dialogImageUrl: "",
      hideUploadG: false,
      fileList: [],
      type: [],
      patents:[],
      Apple:[]
    };
  },
  created() {
    this.release.member_id = JSON.parse(localStorage.getItem("user")).id,
    this.getType();
    this. getPatents()
     this. getApplyClass()
  },
  methods: {
      // 文本域带格式
preText (pretext) {
    return pretext.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')
},
     // 获取专利类别
    getPatents() {
      Patents()
        .then((res) => {
          this.patents = res.data;
        })
        .catch(() => {});
    },
    // 获取产权类型
    getType() {
      PType()
        .then((res) => {
          this.type = res.data;
        })
        .catch(() => {});
    },
     // 产权发布分类
    getApplyClass() {
      ApplyClass()
        .then((res) => {
          this.Apple = res.data;
        })
        .catch(() => {});
    },
    // 发布
    onRelease() {
      // console.log(this.release);
        this.release.ptext = this.preText(this.release.ptext);
      this.release.registration_date =
        new Date(this.release.registration_date).getTime() / 1000;
      this.release.begin_date =
        new Date(this.release.begin_date).getTime() / 1000;
      this.release.end_date = new Date(this.release.end_date).getTime() / 1000;
      this.release.notary_picture = JSON.stringify(this.release.notary_picture);
      PatentAdd(this.release)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("发布成功");
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 产权图片
    fnI(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
      handleCompressImg(file.raw).then((result) => {
        // console.log(result);
        this.Up(result, 1);
      });
    },
    //将图片地址上传
    Up(file) {
      let formData = new FormData();
      formData.append("file", file);

      uploadImg(formData)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.release.picture = res.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },
    handleRemoveG(file, fileList) {
      this.hideUploadG = fileList.length >= 1;
    },
    handlePictureCardPreviewG(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //
    // 产权优势 新增   上传文件
    fn(file, fileList) {
      console.log(this.fileList);
      this.up(file.raw, fileList);
    },
    // 删除文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.up(file.raw, fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    up(file, fileList) {
      let formData = new FormData();
      formData.append("file", file);
      uploadImg(formData)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.release.notary_picture.push(res.data);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
    },

    //
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.release{
	margin-top: 135px;
}
.release .fb_img {
  display: inline-block;
  width: 200px;
  height: 120px;
  vertical-align: bottom;
}
.release .subtmit-btn-box {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-form {
  padding-left: 40px;
}
/deep/.el-textarea__inner {
  resize: none;
}
/deep/.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
/deep/.el-radio {
  margin-bottom: 10px;
}
.el-date-editor.el-input {
  width: 100%;
}

/deep/.el-upload-list {
  display: flex;
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.release{
		margin-top: 15rem;
	}
	.release .release_con{
		padding: 0 1.5rem 3rem;
	}
	.copy-con h3{
		font-size: 2.2rem;
	}
	.release .release_con .title span{
		font-size: 1.6rem;
	}
	/deep/.el-form-item__label{
		width: 8rem !important;
		font-size: 1.4rem;
	}
	/deep/.el-form-item__content{
		margin-left: 8rem !important;
	}
	/deep/.el-radio{
		font-size: 1.4rem;
	}
	/deep/.el-radio__label{
		font-size: 1.4rem;
	}
	/deep/.el-input__inner{
		height: 3rem;
		font-size: 1.4rem;
	}
	/deep/.el-textarea__inner{
		font-size: 1.4rem;
	}
	/deep/.el-button{
		font-size: 1.4rem;
	}
}
</style>